import { ASubmoduleMaterial } from "../models";
import { MATERIAL_TYPES } from "../const";
export function getMaterialType(props: ASubmoduleMaterial | number) {
  let type = undefined;
  if (typeof props === "number") {
    type = props;
  } else {
    type = (props as any).type;
  }
  if (type === MATERIAL_TYPES.lesson) {
    return "lesson";
  } else if (type === MATERIAL_TYPES.question) {
    return "question";
  }
  if (type === MATERIAL_TYPES.task) {
    return "task";
  }

  return "UNKNOWN MATERIAL TYPE";
}
